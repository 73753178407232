import React from "react";

import { Provider as AnalyticsContextProvider } from "@/contexts/analytics/Analytics";
import { Provider as AuthContextProvider } from "@/contexts/auth/Auth";
import { Provider as ErrorContextProvider } from "@/contexts/error/Error";
import { Provider as UserContextProvider } from "@/contexts/user/User";
import { ModalProvider } from "react-modal-hook";
import { BrowserRouter, Route } from "react-router-dom";

import { SentryRoutes } from "@/services/sentry";

import { routes as authRoutes } from "./auth/Auth";
import { routes as consultationRoutes } from "./consultation/Consultation";
import { routes as playlistRoutes } from "./playlist/Playlist";
import { routes as postRoutes } from "./post/Post";
import { PrivateRoute } from "./private-route/PrivateRoute";
import { routes as profileRoutes } from "./profile/ProfileRoutes";
import { PublicRoute } from "./public-route/PublicRoute";
import { routes as pushRoutes } from "./push/Push";

const Oauth2Callback = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/oauth2-callback/Oauth2Callback"
    )
);

const routes = [
  ...authRoutes,
  ...consultationRoutes,
  ...profileRoutes,
  ...pushRoutes,
  ...postRoutes,
  ...playlistRoutes,
];

export interface RouteConfig {
  path: string;
  element: JSX.Element;
  isPrivate?: boolean;
  isPublic?: boolean;
}

export const Router = () => {
  return (
    <BrowserRouter basename="/app">
      <UserContextProvider>
        <ErrorContextProvider>
          <AnalyticsContextProvider>
            <SentryRoutes>
              {routes.map((route) => (
                <Route
                  element={
                    route.isPublic ? (
                      <AuthContextProvider secure={false}>
                        <ModalProvider>
                          <PublicRoute>{route.element}</PublicRoute>
                        </ModalProvider>
                      </AuthContextProvider>
                    ) : route.isPrivate ? (
                      <AuthContextProvider secure={true}>
                        <ModalProvider>
                          <PrivateRoute>{route.element}</PrivateRoute>
                        </ModalProvider>
                      </AuthContextProvider>
                    ) : (
                      <AuthContextProvider>
                        <ModalProvider>{route.element}</ModalProvider>
                      </AuthContextProvider>
                    )
                  }
                  key={route.path}
                  path={route.path}
                />
              ))}
              <Route element={<Oauth2Callback />} path="/oauth2/callback" />
            </SentryRoutes>
          </AnalyticsContextProvider>
        </ErrorContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
};

import { useTranslation } from "react-i18next";

import { Text } from "@leeloo/core";

import * as styles from "./mym-cloud-action-bar.css";

export interface MymCloudActionBarProps<T> {
  selectedMedias: T[];
  button: React.ReactNode;
}

export default function MymCloudActionBar<T>({
  selectedMedias,
  button,
  ...props
}: MymCloudActionBarProps<T>) {
  const { t } = useTranslation();
  return (
    <div
      className={styles.mymCloudActionBar}
      data-testid="mym-cloud-action-bar"
      {...props}
    >
      <Text data-testid="mym-cloud-action-bar-text">
        {t("mymcloud_state_bottombar_selection", {
          selected: selectedMedias.length,
        })}
      </Text>
      {button}
    </div>
  );
}

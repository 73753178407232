import {
  CreatePostParams,
  PostRepository,
} from "@/features/post/domain/repositories/post";

export const postUseCases = (repository: PostRepository) => ({
  createPost: async <T>(params: CreatePostParams): Promise<T> => {
    try {
      if (!params?.medias?.length) {
        throw Error("medias are required");
      }
      if (!("private" in params)) {
        throw Error("private is required");
      }
      if (!params.scheduledAt) {
        throw Error("scheduledAt is required");
      }

      // @TODO To continue with rules allowed extension, max size etc
      return await repository.createPost(params);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getCalendarRestrictions: async <T>(): Promise<T> => {
    try {
      return await repository.getCalendarRestrictions();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getPostCalendarDates: async <T>(): Promise<T> => {
    try {
      return await repository.getPostCalendarDates();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default postUseCases;

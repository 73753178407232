import React, { useState } from "react";

import { Checkbox, Image, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import * as styles from "./media-grid-selection-item.css";

type MediaGridSelectionItem = {
  id: string;
  preview: string;
  type: "video" | "image";
  duration?: number;
  private?: boolean;
};

export interface MediaGridSelectionItemProps<T> {
  media: T;
  checked: boolean;
  disabled?: boolean;
  onSelectItem: (mediaId: string) => void;
}

const MediaGridSelectionItem = <T extends MediaGridSelectionItem>({
  media,
  checked,
  disabled,
  onSelectItem,
  ...props
}: MediaGridSelectionItemProps<T>) => {
  const formatTime = (seconds: number) =>
    new Date(seconds * 1000).toISOString().substring(14, 19);

  return (
    <div
      className={styles.media({ disabled: disabled })}
      onClick={() => onSelectItem(media.id)}
      data-testid="media-grid-selection-item"
      {...props}
    >
      <span
        data-testid="mgsi-overlay"
        className={styles.overlay({ selected: checked })}
      />
      <Checkbox
        data-testid="mgsi-checkbox"
        className={styles.checkbox({ checked: checked })}
        value={media.id}
        key={media.id}
        aria-label="Selected media to create a post"
        onChange={() => onSelectItem(media.id)}
        checked={checked}
      />
      <Image
        src={media?.preview}
        data-testid="mgsi-image"
        className={styles.image}
        alt="Your media"
        loading="lazy"
      />
      {media.type === "video" && (
        <div className={styles.duration} data-testid="mgsi-duration">
          <Icon
            name="camera"
            aria-label="Video icon"
            size="small"
            data-testid="mgsi-duration-icon"
          />
          <Text
            className={styles.text}
            variant="caption"
            data-testid="mgsi-duration-text"
          >
            {formatTime(media.duration || 0)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default MediaGridSelectionItem;

import { isVideo } from "@/utils/fileFormat";
import loadImage from "blueimp-load-image";

export const useProcessImage = () => {
  const processImage = async (file: File) => {
    if (isVideo(file)) {
      return file;
    }

    const processedFile = await loadImage(file, {
      meta: true,
      orientation: true,
      canvas: true,
    })
      .then(function (data: any) {
        if (!data.imageHead) return file;

        return new Promise(function (resolve) {
          data.image.toBlob(function (blob: Blob) {
            data.blob = blob;
            resolve(data);
          }, "image/jpeg");
        });
      })
      .then(function (data: any) {
        return loadImage.replaceHead(data.blob, data.imageHead);
      })
      .then(function (blob: Blob | null) {
        return blob || file;
      })
      .catch(function () {
        return file;
      });

    return new File([processedFile], file.name, {
      type: "image/jpeg",
    });
  };

  return {
    execute: processImage,
  };
};
